<template>
  <div>
    <user-profile-component-global sTextDetailHeader="Perfil del auditor" :sBackHref="sBackHref"
      :bWithPathOrNameRoute="true" :bAdminPermission="false" :bViewPermissionsTable="false" :bViewPermissionsDetailAudit="true"
      :itemsBreadcrumbs="itemsBreadcrumbs" :sApiUserProfile="sApiUserProfile" />
  </div>
</template>

<script>
export default {
  name: "AduitProfile",
  data() {
    return {
      sBackHref: "",
      itemsBreadcrumbs: [],
      sApiUserProfile:""
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Auditorías",
          disabled: false,
          to: { name: "Audit" },
        },
        {
          text: "Perfil del auditor",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "" },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.sApiUserProfile = `${URI}/api/sp/v1/audit/`
      this.sBackHref = `/home/audit`;
    },
  },
  computed: {
    bShowAudit() {
      return this.$store.state.bShowAudit;
    },
    bAdminAudit() {
      return this.$store.state.bAdminAudit;
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>
